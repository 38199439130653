"use client";
import { DescriptionLink } from "@/components/CTA/variants/MultiCourse/descriptions/DescriptionLink";
import { ClientOnly } from "@/components/Cohesion/ClientOnly";

export default function ChangeDescription() {
  let pos = 0;
  return (
    <ClientOnly>
      <div className="Content_intro__4CkTF">
        <p>
          <DescriptionLink
            url="/certificates/professional-certificate"
            text="Professional certificate"
            position={`${++pos}`}
            variant="professional-certificate"
          />{" "}
          programs prepare you to start or advance your career in a variety of
          in-demand fields.
        </p>
        <ul className="list-disc">
          <li>
            Build in-demand skills in AI, business, analytics, computer science,
            and more.
          </li>
          <li>
            Showcase your key skills and valuable knowledge with a valuable
            credential.
          </li>
          <li>
            Prove your proficiency by earning a university-backed certificate in
            just a few months.
          </li>
          <li>
            Access career{" "}
            <DescriptionLink
              url="https://careers.edx.org/guides-and-templates"
              text="guides and templates"
              position={`${++pos}`}
              variant="guides-and-templates"
            />{" "}
            to propel your career forward.
          </li>
        </ul>
        <p>
          <i>Strengthening your </i>résumé<i>? Explore </i>
          <DescriptionLink
            url="/certificates"
            text="certificate programs"
            position={`${++pos}`}
            variant="certificate-programs"
          />
          <b>.</b>
        </p>
      </div>
    </ClientOnly>
  );
}
