"use client";
import { Button } from "@/components/ui/button";
import type { TaxiFormProps } from "@/components/TaxiForm/types";
import useTaxiForm, {
  hideLoadingSkeleton,
} from "@/components/TaxiForm/useTaxiForm";
import "./styles.css";

export default function ExecEdTaxiForm({
  formgrouping,
  formid,
  title,
  postsubmiturl,
  salt,
  brand,
  productvariant,
  category,
  name,
  segmenteventdata,
  taxiFormData,
  formClass,
  showLoading = false,
  locationRestrictions,
  customName,
}: TaxiFormProps) {
  const applyPlaceholders = () => {
    const formElementIds = [
      `${salt}text-field-first_name`,
      `${salt}text-field-last_name`,
      `${salt}text-field-email`,
      `${salt}email`,
    ];
    const selector = formElementIds.map((id) => `#${id}`).join(", ");
    const formElements: NodeListOf<HTMLInputElement> =
      document.querySelectorAll(selector);

    formElements.forEach((element) => {
      switch (element.id) {
        case `${salt}text-field-first_name`:
          element.placeholder = "First Name";
          break;
        case `${salt}text-field-last_name`:
          element.placeholder = "Last Name";
          break;
        case `${salt}text-field-email`:
          element.placeholder = "Email";
          break;
        case `${salt}email`:
          element.placeholder = "Email";
          break;
        default:
          break;
      }
    });

    hideLoadingSkeleton(showLoading, salt || "");
  };

  const { TaxiForm, templateIds } = useTaxiForm({
    formGrouping: formgrouping,
    formId: formid,
    postSubmitUrl: postsubmiturl,
    salt,
    taxiFormData,
    formClass: `exec-ed-taxi-form ${formClass}`,
    segmenteventdata,
    customName,
    productData: {
      brand,
      productvariant,
      category,
      name,
    },
    eventListeners: {
      footerContentRendered: applyPlaceholders,
      noFooterContentRendered: applyPlaceholders,
    },
    showLoading,
    allowedIn: locationRestrictions?.[0]?.allowedIn,
    blockedIn: locationRestrictions?.[0]?.blockedIn,
  });

  const {
    errorMountPointId,
    fieldMountPointId,
    nextActionButtonId,
    footerMountPointId,
  } = templateIds;

  return (
    <TaxiForm>
      <div id={errorMountPointId}></div>
      <div>
        <div id={fieldMountPointId}></div>
        <Button
          id={nextActionButtonId}
          variant="brand"
          size="xl"
          className="w-full"
        >
          Next Step
        </Button>
        <div className="text-xs" id={footerMountPointId}>
          <div className="u--align-left u--padding-top-2 u--font-size-0875 info-text">
            <a></a>
          </div>
        </div>
      </div>
    </TaxiForm>
  );
}
