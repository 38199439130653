"use client";
import React, { useState, useRef, useEffect, ReactNode } from "react";
import { DEFAULT_USE_IN_VIEW_OPTIONS } from "@/constants/tagular/main";
import { useInView } from "react-intersection-observer";

export interface GlobalAccordionProps {
  title?: string;
  children?: ReactNode;
  openOnInit?: boolean;
  titleClassName?: string;
  contentClassName?: string;
  titleWrapperClasses?: string;
  onStateChange?: (isOpen: boolean, title: string) => void;
  onViewed?: (title: string) => void;
}

const GlobalAccordion: React.FC<GlobalAccordionProps> = ({
  title,
  children,
  openOnInit = false,
  titleClassName = "",
  contentClassName = "",
  titleWrapperClasses = "",
  onStateChange,
  onViewed,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(openOnInit);
  const [height, setHeight] = useState<string>("0px");
  const contentRef = useRef<HTMLDivElement | null>(null);

  const updateHeight = (newHeight?: string) => {
    if (contentRef.current) {
      setHeight(
        newHeight === undefined
          ? `${contentRef.current.scrollHeight}px`
          : newHeight,
      );
    }
  };

  const { ref: viewRef, inView } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);

  useEffect(() => {
    if (inView) {
      onViewed?.(title ?? "");
    }
  }, [inView, onViewed, title]);

  // Debounced resize handler
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const handleResize = () => {
      if (isOpen) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => updateHeight(), 100);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    const currentRef = contentRef.current;

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        resizeObserver.disconnect();
      }
      clearTimeout(timeoutId);
    };
  }, [isOpen]);

  const toggleAccordion = () => {
    if (!isOpen) {
      updateHeight();
    } else {
      setHeight("0px");
    }
    setIsOpen(!isOpen);
    onStateChange?.(isOpen, title ?? "");
  };

  return (
    <div className="flex flex-col relative" ref={viewRef}>
      <div
        ref={contentRef}
        style={{ height, overflow: "hidden", transition: "height 0.5s ease" }}
        className={`transition-all duration-500 ${contentClassName}`}
      >
        {children}
      </div>
      <div onClick={toggleAccordion} className={titleWrapperClasses}>
        {title && (
          <p className={`cursor-pointer tracking-wider ${titleClassName}`}>
            {title}
          </p>
        )}
        <button
          aria-label={`Toggle ${title || "accordion"}`}
          className="absolute right-0 bottom-0 p-2 text-gray-600 hover:text-gray-800 transition-transform duration-300"
        />
      </div>
    </div>
  );
};

export default GlobalAccordion;
