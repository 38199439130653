"use client";
import React, { useEffect, useState } from "react";
import Filters from "@/components/icons/Filters";
import CTALink from "@/components/CTA/ctaLink";
import ProductCardList from "@/components/ProductCardList";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ElementType, FormatSubType } from "@/constants/tagular/main";
import { ProductType as CourseType } from "@/lib/course/types";
import { ProductSummary } from "@/lib/productSummary/types";
import getIconFromName from "@/utils/functions/getIconfromName";
import { ProgramType } from "@/lib/program/types";
import { stringToAlgoliaQuery } from "@/lib/algolia/stringToAlgoliaQuery";
import { productTabs } from "./constants";
import { Category, ProductCardTabsByTopicProps } from "./types";
import { personalizeProductList } from "@/lib/productSummary/helper/personalizeProductList";

type CardListProps = {
  products: ProductSummary[];
  isMobile: boolean;
  location: string;
  cardFormatSubtype?: FormatSubType;
};

const CardList = ({
  products,
  isMobile,
  location,
  cardFormatSubtype,
}: CardListProps) => (
  <div className={`${isMobile ? "lg:hidden" : "hidden lg:block"}`}>
    <ProductCardList
      products={products}
      shouldScroll={isMobile}
      location={location}
      cardFormatSubType={cardFormatSubtype}
    />
  </div>
);

const productTypeString = (productType: string): string => {
  if (
    productType === ProgramType.MASTERS ||
    productType === ProgramType.BACHELORS
  ) {
    return `tab=degree-program&learning_type=${productType}`;
  }
  if (productType === ProgramType.CERTIFICATE) {
    return `tab=program`;
  }

  const formattedProductType = productType.replace(/\s+/g, "-");
  return `tab=${formattedProductType}&learning_type=${productType}`;
};

const ProductCardTabsByTopicClient = ({
  title,
  topic,
  categories,
  location = "",
  cardFormatSubtype = FormatSubType.Grid,
}: ProductCardTabsByTopicProps) => {
  const [filteredCategories, setFilteredCategories] =
    useState<Category[]>(categories);
  const [activeCategory, setActiveCategory] = useState<Category | undefined>(
    () => {
      return categories.length > 0 ? categories[0] : undefined;
    },
  );

  useEffect(() => {
    const newCategories =
      categories?.filter(
        (category) => category.productType !== CourseType.BOOTCAMP,
      ) || [];
    setFilteredCategories(newCategories);
  }, [categories]);

  const personalizedCategories = filteredCategories
    .map((category) => {
      const personalizedProducts = personalizeProductList(
        category.products ?? [],
      );
      return {
        ...category,
        products: personalizedProducts,
      };
    })
    .filter((category) => category.products && category.products.length > 0);

  useEffect(() => {
    if (
      !personalizedCategories.find(
        (c) => c.productType === activeCategory?.productType,
      )
    ) {
      setActiveCategory(personalizedCategories[0]);
    }
  }, [personalizedCategories, activeCategory]);

  if (personalizedCategories.length === 0) {
    return null;
  }

  const handleChange = (productType: string) => {
    const selectedCategory = categories.find(
      (category) => category.productType === productType,
    );
    setActiveCategory(selectedCategory);
  };

  return (
    <div className="container py-3">
      <Tabs value={activeCategory?.productType} onValueChange={handleChange}>
        {personalizedCategories.map((category) => (
          <TabsContent key={category.productType} value={category.productType}>
            <h2 className="text-xl lg:text-3xl font-bold">
              Browse online {title} {category.categoryLabel}
            </h2>
            <h4 className="elm-h5 font-normal my-4">
              {productTabs[category.productType]?.subtitle}
            </h4>
            <div className="lg:grid lg:grid-cols-3 lg:gap-4 border-t-solid border-t-[1px] border-b-solid border-b-[1px] py-6 mb-6">
              {productTabs[category.productType]?.data.map((item, index) => (
                <div key={index} className="flex">
                  <div className="w-12 text-primary">
                    {getIconFromName(item.icon)}
                  </div>
                  <div className="px-3 text-primary">
                    <h3 className="mt-0 text-base text-primary">
                      {item.title}
                    </h3>
                    <p className="text-sm">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </TabsContent>
        ))}
        <div className="hidden lg:flex justify-center">
          <TabsList className="shadow-lg rounded-t-xl max-w-[1128px]">
            {personalizedCategories.map((category) => (
              <TabsTrigger
                key={category.productType}
                value={category.productType}
                className="no-underline font-normal text-[16px] text-primary hover:text-primary data-[state=active]:font-bold bg-white rounded-none first:rounded-tl-xl last:rounded-tr-xl"
              >
                {category.categoryLabel} {`(${category.products?.length})`}
              </TabsTrigger>
            ))}
          </TabsList>
        </div>
        <div className="lg:hidden">
          <Select
            value={activeCategory?.productType}
            onValueChange={handleChange}
          >
            <SelectTrigger className="flex items-center border-solid border-[1px] border-primary shadow-none text-primary no-underline">
              <Filters />
              <SelectValue placeholder="Select a product type" />
            </SelectTrigger>
            <SelectContent>
              {personalizedCategories.map((category) => (
                <SelectItem
                  key={category.productType}
                  value={category.productType}
                >
                  {category.categoryLabel}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {personalizedCategories.map((category) => {
          const productType = productTypeString(category.productType);
          const ctaUrl = `/search?${productType}&q=${stringToAlgoliaQuery(topic)}`;

          return (
            <TabsContent
              key={category.productType}
              value={category.productType}
              className="flex flex-col max-w-[1128px] mx-auto"
            >
              <h3 className="mb-4">{category.categoryLabel}</h3>
              <CardList
                products={category.products?.slice(0, 16) || []}
                isMobile={false}
                location={location}
                cardFormatSubtype={cardFormatSubtype}
              />
              <CardList
                products={category.products?.slice(0, 16) || []}
                isMobile={true}
                location={location}
                cardFormatSubtype={cardFormatSubtype}
              />
              <div className="flex justify-center mx-auto mt-5">
                <CTALink
                  location={location}
                  label={`Explore all ${category.categoryLabel}`}
                  url={ctaUrl}
                  html_id={`explore-all-${category.productType.replaceAll(" ", "-").toLowerCase()}-button`}
                  name={topic}
                  variant={category.productType}
                  elementType={ElementType.Entry}
                  size="lg"
                />
              </div>
            </TabsContent>
          );
        })}
      </Tabs>
    </div>
  );
};

export default ProductCardTabsByTopicClient;
