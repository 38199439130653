"use client";
import React, { useState } from "react";
import Icon from "@/components/icons";
import isHlsVideo from "./utils/isHlsVideo";
import YouTubeVideoPlayer from "./components/YoutubeVideoPlayer";
import HLSVideoPlayer from "./components/HLSVideoPlayer";
import convertToNocookieUrl from "./utils/convertToNoCookieUrl";
import Image from "next/image";
import { cn } from "@/lib/utils";

const VideoPlayer = ({
  videoClassName,
  videoContainerClassName,
  title,
  videoUrl,
  videoThumbnail,
  autoplay,
  thumbnailClassName,
  thumbnailWidth = 288,
  thumbnailHeight = 176,
}: {
  videoClassName?: string;
  videoContainerClassName?: string;
  title: string;
  videoUrl: string;
  videoThumbnail?: string;
  autoplay?: boolean;
  thumbnailClassName?: string;
  thumbnailWidth?: number;
  thumbnailHeight?: number;
}) => {
  const videoUrlIsHls = isHlsVideo(videoUrl);
  const videoSrc = videoUrlIsHls ? videoUrl : convertToNocookieUrl(videoUrl);
  const [videoActive, setVideoActive] = useState(autoplay || false);

  return (
    <div
      className={`${videoContainerClassName} flex justify-center items-center`}
    >
      {!videoActive && videoThumbnail && (
        <Image
          src={videoThumbnail}
          alt=""
          width={thumbnailWidth}
          height={thumbnailHeight}
          className={cn(
            "relative object-contain max-h-44 max-w-72",
            thumbnailClassName,
          )}
        />
      )}
      {videoActive ? (
        <>
          {!videoUrlIsHls ? (
            <YouTubeVideoPlayer
              videoClassName={videoClassName}
              title={title}
              videoUrl={videoSrc}
            />
          ) : (
            <HLSVideoPlayer
              videoClassName={videoClassName}
              videoUrl={videoSrc}
            />
          )}
        </>
      ) : !videoSrc.startsWith("null") ? ( // yes, this is checking for the string "null". TODO: Remove "null" string values from video player URLs
        <button
          className="flex gap-2 items-center font-medium py-2 px-6 bg-stone-50 hover:bg-primary-500 hover:text-white text-primary-500 border-gray-100
          	shadow-[0_2px_4px_0px_rgba(0,0,0,0.14)] absolute rounded-full"
          onClick={() => {
            setVideoActive(true);
          }}
        >
          <span>
            <Icon type="play-circle" />
          </span>
          Play Video
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default VideoPlayer;
