"use client";
import React, { useEffect, useState } from "react";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";

type Props = {
  title: string;
  rootClassName?: string;
  itemClassName?: string;
  headerClassName?: string;
  triggerClassName?: string;
  iconClassName?: string;
  contentClassName?: string;
  childrenClassName?: string;
  children?: React.ReactNode;
};
export default function AccordionContent({
  title,
  rootClassName,
  itemClassName,
  headerClassName,
  triggerClassName,
  iconClassName,
  contentClassName,
  childrenClassName = "py-4",
  children,
}: Props) {
  const [openItem, setOpenItem] = useState("");

  const hashToOpen: { [key: string]: string } = {
    "#whygetsmarter": "Why GetSmarter?",
    "#brochure": "Request more info",
  };

  const handleHashChange = () => {
    const hash = hashToOpen[window.location.hash] || "";
    if (hash) {
      setOpenItem(hash);
    }
  };

  useEffect(() => {
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <Accordion.Root
      type="single"
      collapsible
      className={rootClassName}
      value={openItem}
      onValueChange={setOpenItem}
    >
      <Accordion.Item value={title} className={itemClassName}>
        <Accordion.Header className={cn("flex m-0", headerClassName)}>
          <Accordion.Trigger
            className={cn(
              "bg-[#fbfaf9] md:bg-putty-200 flex flex-1 py-2 px-5 mt-2 items-center justify-between text-lg text-left font-bold transition-all [&[data-state=open]>svg]:rotate-180",
              triggerClassName,
            )}
          >
            {title}
            <ChevronDownIcon
              className={cn(
                "h-5 w-5 shrink-0 text-brand font-bold transition-transform duration-200",
                iconClassName,
              )}
            />
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content
          className={cn(
            "overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
            contentClassName,
          )}
        >
          <div className={childrenClassName}>{children}</div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
}
