"use client";

import * as React from "react";
import styles from "./index.module.css";

export default function Default({ id, children }: any) {
  const handleHashChange = () => {
    const hash = window.location.hash.split("#")[1] || "";
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  React.useEffect(() => {
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <div className={styles.content} id={id}>
      {children}
    </div>
  );
}
