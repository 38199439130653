"use client";
import React from "react";
import ProductCardListByUUIDClient from "@/components/ProductCardListWrapper/variants/ProductCardListByUUID/ProductCardListByUUIDClient";
import IconTextList from "@/components/TextLists/IconTextList/IconTextList";
import IconTextListItem from "@/components/TextLists/IconTextList/IconTextListItem";
import { ProductType as CourseType } from "@/lib/course/types";
import { ProductSummary } from "@/lib/productSummary/types";
import { NO_RESULTS, productTypeIconList } from "./constants";
import { cn } from "@/lib/utils";

export type ProductCardIconListProps = {
  products: ProductSummary[] | null;
  productType: string;
  title?: string;
};

export default function ProductCardIconList({
  products,
  productType,
  title,
}: ProductCardIconListProps) {
  if (productType === CourseType.BOOTCAMP) {
    return null;
  }

  const headerData = productTypeIconList[productType];
  const visibleProducts = products ? products : [];
  return (
    <div>
      {title && <h2 className="text-2xl my-6">{title}</h2>}
      <div
        className={cn(
          "pt-16 pb-8 bg-putty-100 fullwidth",
          !products && "animate-pulse-bg-putty",
        )}
      >
        {!visibleProducts.length ? (
          <p>{NO_RESULTS}</p>
        ) : (
          <>
            <IconTextList className="py-0 pt-8">
              {headerData?.data.map((header, key) => (
                <IconTextListItem
                  key={key}
                  icon={header.icon}
                  header={header.title}
                  description={header.description}
                />
              ))}
            </IconTextList>

            <ProductCardListByUUIDClient
              products={visibleProducts}
              key={"SubcategoryProductList"}
              showAllProducts
              className="flex-nowrap overflow-x-scroll md:flex-wrap md:overflow-x-auto gap-6"
              cardWrapperClassName="mb-0 -mt-4"
            />
          </>
        )}
      </div>
    </div>
  );
}

ProductCardIconList.hasProducts = true;
