import React from "react";

const Disclaimer = ({
  text = "Technology and services powered by 2U, Inc., the parent company of edX.",
  overrideStyle = "",
}: {
  text?: string;
  overrideStyle?: string;
}) => {
  return (
    <div>
      {overrideStyle ? (
        <span className={overrideStyle}>{text}</span>
      ) : (
        <p className="py-8 mb-0 text-[1.375rem] text-center font-bold text-brand">
          {text}
        </p>
      )}
    </div>
  );
};

export default Disclaimer;
