"use client";
import { TaxiFormProps } from "@/components/TaxiForm/types";
import "./style.css";
import useTaxiForm from "@/components/TaxiForm/useTaxiForm";

export default function TaxiFormMain({
  formgrouping,
  formid,
  title,
  subtitle,
  postsubmiturl,
  salt,
  brand,
  productvariant,
  category,
  name,
  segmenteventdata,
  taxiFormData,
  formClass,
  showLoading = false,
  locationRestrictions,
  containerClass = "card",
}: TaxiFormProps) {
  const { TaxiForm, templateIds } = useTaxiForm({
    formGrouping: formgrouping,
    formId: formid,
    postSubmitUrl: postsubmiturl,
    salt,
    taxiFormData,
    formClass,
    segmenteventdata,
    productData: {
      brand,
      productvariant,
      category,
      name,
    },
    showLoading,
    allowedIn: locationRestrictions?.[0]?.allowedIn,
    blockedIn: locationRestrictions?.[0]?.blockedIn,
  });

  const {
    titleId,
    introTextId,
    progressBarId,
    errorMountPointId,
    fieldMountPointId,
    nextActionButtonId,
    footerMountPointId,
  } = templateIds;

  return (
    <TaxiForm>
      <div className="u--background-light">
        <div className={containerClass}>
          <div className="card__body">
            <div className="form-message">
              <div className="header-wrapper">
                <h2 className="!bg-transparent" id={titleId}>
                  {title}
                </h2>
              </div>
              <p id={introTextId}>{subtitle}</p>
            </div>
            <div
              className="progressBar progress--theme--green"
              id={progressBarId}
            >
              <div className="progress__max" hidden>
                <div className="progress__bar"></div>
              </div>
            </div>
          </div>
          <div id={errorMountPointId}>
            <div className="card__body" hidden>
              <div className="u--border-danger u--border-left u--padding-2">
                <div className="h4 u--text-danger"></div>
                <div>
                  <label className="is--invalid"></label>
                  <a className="u--text-danger"></a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="card__body u--border-top">
                <div id={fieldMountPointId}></div>
              </div>
              <div className="card__footer">
                <button
                  id={nextActionButtonId}
                  className="button--theme-cta"
                  type="button"
                >
                  Next Step
                </button>
                <div id={footerMountPointId}>
                  <div className="u--align-left u--padding-top-2 u--font-size-0875 info-text">
                    <a></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TaxiForm>
  );
}
