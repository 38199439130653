"use client";
import { useEffect, useState } from "react";
import { CTAProps } from "../types";
import CTABanner from "./Banner";
import CTALink from "@/components/CTA/ctaLink";
import { getPageType } from "@/utils/tagular/helpers";
import { PageNames } from "@/constants/tagular/main";

export default function Register({ theme = "light" }: CTAProps) {
  const [pageType, setPageType] = useState("unknown");
  useEffect(() => {
    const page = getPageType();
    if (page) {
      setPageType(page);
    }
  }, []);
  return (
    <CTABanner
      title="Maximize Your Potential"
      subtitle="Sign up for special offers, career resources, and recommendations that will help you grow, prepare, and advance in your career."
      theme={theme}
      fullsize={false}
    >
      <CTALink
        label="Register for free"
        url="https://authn.edx.org/register"
        type="brand"
        location={
          pageType === PageNames.ShopPage
            ? "bottom-registration-banner"
            : "register-free"
        }
        html_id={
          pageType === PageNames.ShopPage
            ? "registration-CTA"
            : "register-free-CTA"
        }
      />
    </CTABanner>
  );
}
