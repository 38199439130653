"use client";
import { Button } from "@/components/ui/button";
import type { TaxiFormProps } from "@/components/TaxiForm/types";
import useTaxiForm from "@/components/TaxiForm/useTaxiForm";
import "./styles.css";

export default function CondensedTaxiForm({
  formgrouping,
  formid,
  title,
  postsubmiturl,
  salt,
  brand,
  productvariant,
  category,
  name,
  segmenteventdata,
  taxiFormData,
  formClass,
  showLoading = false,
  locationRestrictions,
}: TaxiFormProps) {
  const { TaxiForm, templateIds } = useTaxiForm({
    formGrouping: formgrouping,
    formId: formid,
    postSubmitUrl: postsubmiturl,
    salt,
    taxiFormData,
    formClass: `condensed-taxi-form bg-putty-200 border border-putty-400 p-4 lg:p-10 rounded-xl w-fill lg:w-[410px] ${formClass}`,
    segmenteventdata,
    productData: {
      brand,
      productvariant,
      category,
      name,
    },
    showLoading,
    allowedIn: locationRestrictions?.[0]?.allowedIn,
    blockedIn: locationRestrictions?.[0]?.blockedIn,
  });

  const {
    titleId,
    progressBarId,
    errorMountPointId,
    fieldMountPointId,
    nextActionButtonId,
    footerMountPointId,
  } = templateIds;

  return (
    <TaxiForm>
      <div className="scroll-mt-4 lg:scroll-mt-10">
        <h2
          id={titleId}
          className="!bg-transparent text-center !mt-0 !text-lg md:!text-2xl"
        >
          {title}
        </h2>
        <div id={progressBarId}>
          <div className="progress__max" hidden>
            <div className="progress__bar !bg-primary-dark !border !border-primary-dark"></div>
          </div>
        </div>
      </div>
      <div id={errorMountPointId}>
        <div className="card__body" hidden>
          <div className="u--border-danger u--border-left u--padding-2">
            <div className="h4 u--text-danger"></div>
            <div>
              <label className="is--invalid"></label>
              <a className="u--text-danger"></a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div id={fieldMountPointId}></div>
          <Button
            id={nextActionButtonId}
            variant="brand"
            size="xl"
            className="w-full"
          >
            Next Step
          </Button>
          <div id={footerMountPointId}>
            <div className="u--align-left u--padding-top-2 u--font-size-0875 info-text">
              <a></a>
            </div>
          </div>
        </div>
      </div>
    </TaxiForm>
  );
}
