"use client";
import React from "react";
import { InfoBoxProps } from "../types";
import Image from "next/image";
import { Button } from "@/components/ui/button";
import styles from "../InfoBox.module.css";
import { useRouter } from "next/navigation";
import DisclaimerNoStyling from "@/components/Disclaimer";

export default function DegreeStatisticalInfoBox({
  heading,
  icon,
  statisticLabel,
  statistic,
  ctaTitle,
  ctaLink,
  className = "",
  children,
}: InfoBoxProps) {
  const router = useRouter();

  const removeTabNamesFromPath = (src: string): string => {
    const itemsToRemove = [
      "overview",
      "tuition",
      "curriculum",
      "admissions",
      "legal",
    ];
    const words = src.split("/");
    const filteredWords = words.filter((word) => !itemsToRemove.includes(word));
    return filteredWords.join("/");
  };

  const handleClick = () => {
    if (ctaLink) {
      router.push(
        `${removeTabNamesFromPath(window.location.pathname)}/${ctaLink}`,
      );
    }
  };

  return (
    <div className={`container ${className}`}>
      <div className="w-full text-center lg:text-left">
        <h2 className="text-5xl">{heading}</h2>
      </div>
      <div className="w-full lg:flex lg:flex-row-reverse">
        <div
          className={
            styles.statisticsSection +
            ` flex flex-col items-center pb-6 md:pb-0`
          }
        >
          <Image src={icon} width={28} height={28} alt={heading || ""} />
          <span className="text-sm font-roboto my-2">{statisticLabel}</span>
          <span className="font-roboto text-lg font-bold text-center">
            {statistic}
          </span>
        </div>
        <div className="border-t-[1px] lg:border-none lg:w-2/3 md:pr-8">
          <span className="text-base lg:mr-5">
            {children}
            <DisclaimerNoStyling
              overrideStyle="text-base font-bold"
              key={heading}
            />
          </span>
        </div>
      </div>
      {ctaTitle && ctaLink && (
        <div className="w-full flex justify-center">
          <Button className="text-base" variant="outline" onClick={handleClick}>
            {ctaTitle}
          </Button>
        </div>
      )}
    </div>
  );
}
