"use client";
import React, { Children, isValidElement } from "react";
import type { DynamicProductListItem } from "@/components/CTA/variants/DynamicProductList/Items";
import DynamicProductListItems from "@/components/CTA/variants/DynamicProductList/Items";
import { indexChildren } from "@/utils/tagular/reactHelpers";

type ShortCodeDynamicProductListItemsProps = {
  children: React.ReactNode[];
};

export function ShortCodeDynamicProductListItems({
  children,
}: ShortCodeDynamicProductListItemsProps) {
  const productList: DynamicProductListItem[] = [];
  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      const element = child as React.ReactElement<{
        category: string;
        children: React.ReactNode[];
      }>;
      productList.push({
        category: element?.props?.category as string,
        productList: indexChildren(element?.props?.children),
      });
    }
  });
  return <DynamicProductListItems products={productList} />;
}

ShortCodeDynamicProductListItems.hasProducts = true;

type ShortCodeDynamicProductListItemProps = {
  category: string;
  children: React.ReactNode[];
};

export function ShortCodeDynamicProductListItem({
  children,
}: ShortCodeDynamicProductListItemProps) {
  return <div>{children}</div>;
}

ShortCodeDynamicProductListItem.hasProducts = true;
