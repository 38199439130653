"use client";
import { personalizeProductList } from "@/lib/productSummary/helper/personalizeProductList";

import { ProductSummary } from "@/lib/productSummary/types";
import { ReactNode } from "react";
import React from "react";
import Message from "../Message";

const LocationBasedContentWrapperClient = ({
  children,
  productSummaries,
  renderProductCardList,
  copy,
}: {
  children: ReactNode;
  productSummaries?: ProductSummary[];
  renderProductCardList: ReactNode;
  copy?: string;
}) => {
  const availableProducts = personalizeProductList(productSummaries || []);
  const isEmptyHub = availableProducts.length === 0;
  return (
    <>
      {isEmptyHub ? (
        <>
          <Message variant="no-degree" copy={copy} />
          <h2
            id={`explore-${copy === "masters" ? "online-masters" : copy === "bachelors" ? "bachelors" : "doctorate"}-degrees`}
            className="mt-4"
          >
            Explore related programs and courses
          </h2>
          {renderProductCardList}
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default LocationBasedContentWrapperClient;
