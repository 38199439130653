"use client";

import { PropsWithChildren, useEffect, useState } from "react";
import Script from "next/script";
import {
  LeadFormEventListeners,
  LeadFormPluginOptions,
} from "@/types/LeadFormPlugin";
import type { FormData } from "./types";
import Base64Encode from "@/components/Base64Encode";
import { getCorrelationID, hyphenateForTagular } from "@/utils/tagular/helpers";
import { translateVariant } from "@/utils/tagular/dataTranslationMatrices";
import { ConversionTracked } from "@/hooks/eventing/types/core.conversions.ConversionTracked.v2";
import makeEvent from "@/hooks/eventing/makeEvent";
import { EVENTS } from "@/hooks/eventing/types/events";
import { sendTaxiFormEvent, TaxiFormSegmentEventData } from "@/lib/segment";
import Message from "../ContentBlock/variants/Message";
import { Skeleton } from "../ui/skeleton";
import { validateLocation } from "@/utils/functions/validateLocation";

type FormProductData = {
  brand?: string;
  category?: string;
  productvariant?: string;
  name?: string;
};

type CohesionProductData = FormProductData & {
  action: string;
  customName?: string;
};

const formSkeletonId = "taxi_form_footer_loading";
const formContainerId = "taxi_form_internal_container";

const LoadingSkeleton = ({ formSalt }: { formSalt: string }) => (
  <div className="space-y-4" id={`${formSalt}_${formSkeletonId}`}>
    <div className="space-y-2 mb-20">
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-10 w-full" />
    </div>
    <Skeleton className="h-10 w-full" />
  </div>
);

export type UseTaxiFormProps = {
  formGrouping: string;
  formId: string;
  postSubmitUrl: string;
  salt?: string;
  taxiFormData?: FormData | null;
  eventListeners?: LeadFormEventListeners;
  isLoading?: boolean;
  formClass?: string;
  segmenteventdata?: TaxiFormSegmentEventData;
  productData: FormProductData;
  showLoading?: boolean;
  allowedIn?: string[];
  blockedIn?: string[];
  customName?: string;
};

const generateCohesionPayload = ({
  action,
  brand,
  category,
  productvariant,
  name,
  customName,
}: CohesionProductData) =>
  ({
    correlation: {
      id: getCorrelationID(),
    },
    metadata: {
      category: "Lead",
      action,
      timestamp: new Date().toISOString(),
      customName,
      productList: [
        {
          brand: brand ?? "",
          variant: translateVariant(productvariant ?? ""),
          category: category,
          name: name,
        },
      ],
    },
  }) as ConversionTracked;

const trackNextStep = (productData: FormProductData) => {
  const payload = generateCohesionPayload({
    ...productData,
    action: "Started",
  });

  const event = makeEvent<ConversionTracked, EVENTS>(payload);
  event<ConversionTracked>(EVENTS.ConversionTracked)();
};

const trackSubmitted = (
  segmenteventdata?: TaxiFormSegmentEventData,
  productData?: FormProductData,
  customName?: string,
) => {
  const payload = generateCohesionPayload({
    ...productData,
    action: "Submitted",
    customName,
  });

  const event = makeEvent<ConversionTracked, EVENTS>(payload);
  event<ConversionTracked>(EVENTS.ConversionTracked)();

  if (segmenteventdata) {
    sendTaxiFormEvent(segmenteventdata);
  }
};

export function hideLoadingSkeleton(showLoading: boolean, salt: string) {
  // Taxi LeadFormPlugin cannot hanlde react state changes, so we need to resort to vanillajs
  if (showLoading) {
    document
      .getElementById(`${salt}_${formSkeletonId}`)
      ?.classList.add("hidden");
    document
      .getElementById(`${salt}_${formContainerId}`)
      ?.classList.remove("hidden");
  }
}

export default function useTaxiForm({
  formGrouping,
  formId,
  postSubmitUrl,
  salt = "___",
  taxiFormData,
  eventListeners,
  isLoading,
  formClass,
  segmenteventdata,
  productData,
  showLoading = false,
  allowedIn = [],
  blockedIn = [],
  customName,
}: UseTaxiFormProps) {
  const [isTaxiScriptLoaded, setIsTaxiScriptLoaded] = useState(false);
  const resolvedFormId = formId;
  const packageFromId = `${salt}-${formGrouping}-${resolvedFormId}-full-taxi-lead-form-package`;
  const templateFromId = `${salt}-lead-form-template`;
  const mountPointId = `${salt}-taxi-plugin-mount-point`;
  const titleId = `${salt}-title`;
  const introTextId = `${salt}-intro_text`;
  const progressBarId = `${salt}-lead-form-progress-bar-mount-point`;
  const errorMountPointId = `${salt}-lead-form-error-mount-point`;
  const fieldMountPointId = `${salt}-lead-form-field-mount-point`;
  const nextActionButtonId = `${salt}-lead-form-next-action-button`;
  const footerMountPointId = `${salt}-lead-form-footer-mount-point`;
  const isValidLocation = validateLocation(allowedIn, blockedIn);

  // Initialize TaxiForm once script is loaded and dependencies are ready
  useEffect(() => {
    if (isLoading || !isTaxiScriptLoaded) return;
    if (!taxiFormData || !isValidLocation) return;

    const options: LeadFormPluginOptions = {
      salt: salt,
      thankYouURL: postSubmitUrl,
      packageType: "element",
      packageFrom: packageFromId,
      templateType: "element",
      templateFrom: templateFromId,
      telemetryEnabled: false,
      hostPresetFields: {
        ip: "", // window.visitor?.ip?.address || "0.0.0.0",
        lead_source: "edx",
        // page_url: pageUrlWithEAID,
        eaid: "",
        rv_source: "organic",
        // splash_creative: '', // TODO: figure out how to supply this
      },
    };

    // Event should only fire on the first instance of Next Step
    let hasFirstStepRun = false;

    options.engineEventListeners = {
      nextScreenTransitionSucceeded: () => {
        if (!hasFirstStepRun) {
          hasFirstStepRun = true;
          trackNextStep(productData);
        }
      },
      imqSubmissionAttempt: () => {
        trackSubmitted(segmenteventdata, productData, customName);
      },
      footerContentRendered: () => {
        hideLoadingSkeleton(showLoading, salt);
      },
      noFooterContentRendered: () => {
        hideLoadingSkeleton(showLoading, salt);
      },
    };

    if (eventListeners) {
      options.engineEventListeners = {
        ...options.engineEventListeners,
        ...eventListeners,
      };
    }

    const taxiForm = new window.LeadFormPlugin(
      window.document,
      mountPointId,
      options,
    );
    taxiForm.start();

    //fallback in case no event to remove the loading is triggered
    if (showLoading) {
      setTimeout(() => {
        hideLoadingSkeleton(showLoading, salt);
      }, 3000);
    }
  }, [
    isTaxiScriptLoaded,
    mountPointId,
    packageFromId,
    postSubmitUrl,
    salt,
    templateFromId,
    taxiFormData,
    isLoading,
    isValidLocation,
    eventListeners,
    showLoading,
    productData,
    segmenteventdata,
  ]);

  // Check if script is already loaded in window when component mounts
  useEffect(() => {
    const checkInterval = 300;
    const maxPollingTime = 30000;

    const intervalId = setInterval(() => {
      if (typeof window !== "undefined" && window.LeadFormPlugin) {
        setIsTaxiScriptLoaded(true);
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      }
    }, checkInterval);

    const timeoutId = setTimeout(() => {
      window.newrelic?.noticeError("TaxiForm script failed to load", {
        formId: resolvedFormId,
      });
      clearInterval(intervalId);
    }, maxPollingTime);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [resolvedFormId]);

  const TaxiForm = ({ children }: PropsWithChildren) => {
    if (!taxiFormData || isLoading || !isValidLocation) return null;

    return (
      <>
        {showLoading ? <LoadingSkeleton formSalt={salt} /> : null}
        <section className="taxi-form-section" data-testid="taxi-form">
          <div className={`taxi-form ${formClass || ""}`}>
            <pre id={packageFromId} className="hidden">
              {JSON.stringify(taxiFormData)}
            </pre>
            <div
              id={mountPointId}
              data-package-type="element"
              data-template-type="element"
            />
          </div>
          <div
            id={templateFromId}
            className="hidden"
            aria-hidden="true"
            data-encoded="base64"
          >
            <Base64Encode>
              <div
                className={showLoading ? "hidden" : ""}
                id={`${salt}_${formContainerId}`}
              >
                {children}
              </div>
            </Base64Encode>
          </div>
        </section>
      </>
    );
  };

  return {
    TaxiForm,
    templateIds: {
      titleId,
      introTextId,
      progressBarId,
      errorMountPointId,
      fieldMountPointId,
      nextActionButtonId,
      footerMountPointId,
    },
    hideLoadingSkeleton,
  };
}
