import { PropsWithChildren } from "react";
import { renderToStaticMarkup } from "react-dom/server";

const Base64Encode = ({ children }: PropsWithChildren) => {
  const componentString: string = renderToStaticMarkup(children);
  const component: string = btoa(componentString);
  return component;
};

export default Base64Encode;
