"use client";
import { Button } from "@/components/ui/button";

import { ClientOnly } from "@/components/Cohesion/ClientOnly";
import { useInView } from "react-intersection-observer";
import { DEFAULT_USE_IN_VIEW_OPTIONS } from "@/constants/tagular/main";
import { useEffect } from "react";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import { ElementType } from "@/constants/tagular/main";
import { hyphenateForTagular } from "@/utils/tagular/helpers";

function InnerRegisterNow() {
  const props = { html_id: "register-now-CTA", location: "goal-path-asset" };
  const label = "Register now";
  const url = "https://authn.edx.org/register";

  const webElement: ElementViewed["webElement"] = {
    elementType: ElementType.Button,
    htmlId: props.html_id,
    location: props.location,
    position: "1",
    text: label,
    name: hyphenateForTagular(label),
  };

  const { inView, ref } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);
  const { elementViewed, elementClicked } = useElementEvents({ webElement });

  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [inView, elementViewed]);

  const handleClick = () => {
    elementClicked({ outboundUrl: url });
  };

  return (
    <Button
      ref={ref}
      variant="brand"
      size="lg"
      className="w-full h-auto md:w-max py-[0.5625rem] font-normal"
      asChild
      onClick={handleClick}
    >
      <a className="no-underline hover:no-underline" href={url}>
        {label}
      </a>
    </Button>
  );
}

export default function RegisterNow() {
  return (
    <ClientOnly>
      <InnerRegisterNow />
    </ClientOnly>
  );
}
