import CalendarToday from "@/components/icons/CalendarToday";
import CircleCheck from "@/components/icons/CircleCheck";
import Clock from "@/components/icons/Clock";
import { TrendingUp } from "@/components/icons";
import TrendingPoints from "@/components/icons/TrendingPoints";
import CustomerService from "@/components/icons/CustomerService";
import Timelapse from "@/components/icons/Timelapse";
import People from "@/components/icons/People";
import Money from "@/components/icons/Money";
import MoneyOutlined from "@/components/icons/MoneyOutline";
import Certificate from "@/components/icons/Certificate";
import EventBusy from "@/components/icons/EventBusy";
import Person from "@/components/icons/Person";
import Speedometer from "@/components/icons/Speedometer";

const getIconFromName = (icon: string, iconSize?: string): React.ReactNode => {
  const size = iconSize ?? "48";

  switch (icon) {
    case "calendar-today":
      return <CalendarToday svgProps={{ width: size, height: size }} />;
    case "check-circle":
      return <CircleCheck svgProps={{ width: size, height: size }} />;
    case "clock":
      return <Clock svgProps={{ width: size, height: size }} />;
    case "event-busy":
      return <EventBusy svgProps={{ width: size, height: size }} />;
    case "trending-up":
      return <TrendingUp svgProps={{ width: size, height: size }} />;
    case "trending-points":
      return <TrendingPoints svgProps={{ width: size, height: size }} />;
    case "customer-service":
      return <CustomerService svgProps={{ width: size, height: size }} />;
    case "people":
      return <People svgProps={{ width: size, height: size }} />;
    case "timelapse":
      return <Timelapse svgProps={{ width: size, height: size }} />;
    case "money":
      return <Money svgProps={{ width: size, height: size }} />;
    case "money-outline":
      return <MoneyOutlined svgProps={{ width: size, height: size }} />;
    case "person":
      return <Person svgProps={{ width: size, height: size }} />;
    case "speedometer":
      return <Speedometer svgProps={{ width: size, height: size }} />;
    case "certificate":
      return (
        <Certificate
          svgProps={{ width: size, height: size, viewBox: "0 0 48 35" }}
        />
      );
    default:
      return null;
  }
};

export default getIconFromName;
