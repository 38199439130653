import { ReactNode } from "react";
import { cn } from "@/lib/utils";

export interface IconTextListProps {
  children: ReactNode;
  className?: string;
  small?: boolean;
  fullwidth?: boolean;
  noWrap?: boolean;
}

export default function IconTextList({
  children,
  className = "",
  small = false,
  fullwidth = true,
  noWrap = true,
}: IconTextListProps) {
  const baseClassName = `px-4 md:px-0 background-light ${small ? "py-2" : "py-8"} ${fullwidth ? "fullwidth" : ""}`;
  return (
    <div className={cn(baseClassName, className)}>
      <div className="w-full">
        <div
          className={`md:flex ${noWrap ? "flex-nowrap space-x-24" : "flex-wrap gap-y-4"} w-full`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
