"use client";
import Image from "next/image";

import { CTAProps } from "@/components/CTA/types";
import { validateLocation } from "@/utils/functions/validateLocation";

export default function CTABanner({
  title,
  subtitle,
  titleSize = "large",
  emphasizeTitle = false,
  theme = "light",
  background_image,
  border,
  children,
  fullsize = true,
  children_container_classname,
  locationRestrictions,
}: CTAProps) {
  const isValidLocation = validateLocation(
    locationRestrictions?.allowedIn || [],
    locationRestrictions?.blockedIn || [],
  );
  const bgVariants = {
    default: "bg-card",
    light: "bg-putty-200",
    gray: "bg-putty-light",
    dark: "bg-primary",
    tones: "bg-muted",
    "light-dark": "bg-primary-300",
    transparent: "",
    "dark-gradient": "bg-gradient-to-b from-primary to-transparent",
  };

  const titleSizeVariants = {
    small: "text-xl lg:text-2xl",
    default: "text-3xl lg:text-5xl",
    large: "text-4xl lg:text-7xl",
  };

  const fontColorTheme = {
    default: "text-secondary",
    light: "text-secondary",
    gray: "text-secondary",
    dark: "text-white",
    tones: "text-secondary",
    "light-dark": "text-white",
    transparent: "text-primary",
    "dark-gradient": "text-primary-foreground",
  };

  const fontWeigthTheme = {
    default: "font-extrabold",
    light: "font-extrabold",
    gray: "font-extrabold",
    dark: "font-extrabold",
    tones: "font-extrabold",
    "light-dark": "font-extrabold",
    transparent: "font-black",
    "dark-gradient": "font-black",
  };
  return isValidLocation ? (
    <div
      className={`relative overflow-hidden py-3
        ${fullsize ? "mx-break-out" : ""} ${
          !background_image ? bgVariants[theme] : ""
        } ${border ? "border" : ""}`}
    >
      {background_image && (
        <Image
          src={background_image}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          alt=""
          className="absolute m-0 z-[-1]"
        />
      )}

      <div
        className={`container flex flex-col items-center justify-center text-center ${
          background_image ? "py-28" : "py-10"
        } ${background_image ? "" : bgVariants[theme]}`}
      >
        <h2
          className={`${fontColorTheme[theme]} ${fontWeigthTheme[theme]} scroll-m-20 tracking-tight ${
            titleSizeVariants[titleSize]
          } ${emphasizeTitle ? "italic" : ""} font-black font-inter mt-0`}
        >
          {title}
        </h2>
        {subtitle && (
          <p
            className={`md:px-24 py-3 text-base text-center ${
              theme === "dark" ? "text-white" : ""
            }`}
          >
            {subtitle}
          </p>
        )}
        <div className={`flex gap-4 ${children_container_classname}`}>
          {children}
        </div>
      </div>
    </div>
  ) : null;
}
